import React,{Fragment} from 'react';
import Contact from "../../container/contact";
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterNav from "../../components/footer/FooterThree";


const ContactPage = () => {
    return (
        <Fragment>
              <Header classes={'project-header fixed-top'}/>
            <SideHeader mobile={true}/>


            <div className="bg-white section ">
            <div className="content-body bg-white section" style={{height:"100%", paddingTop:"200px",minHeight:"93vh",paddingBottom:"120px!important"}}>
            
            <Contact/>
            </div>
            </div>
          {/**   </div>*/}
           {/** <div class="bg-projekte section">
           
            </div> */}
            <FooterNav position={'static'}/>
        </Fragment>
    );
};

export default ContactPage;