import React, {useRef, useImperativeHandle, forwardRef} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Portfolio from "../../components/portfolio";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import portfolioAuswahl from '../../data/portfolio/Projektauswahl';



let PortfolioAuswahl = (props, ref) => {

    const slider = useRef(null);

    useImperativeHandle(ref, () => ({
        slickNext: () => {
            slider.current.slickNext();
        },
        slickPrev: () => {
            slider.current.slickPrev();
        }
    }));

    const carouselSettings = {
        arrows: false,
        autoplay: false,
        autoplaySpeed: 2500,
        dots: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        infinite: true,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                }
            }
        ]
    };

    return (
        <div className="content-body section">
            <div className="section-wrap section">
                <Container fluid>
               
                    <Row>
                        <Col className={'p-0'}>
                     
                            <Slider ref={slider} {...carouselSettings}>
                             

{portfolioAuswahl.map((portfolio,index) => (
                                    <div key={portfolio.link+index} className="col pl-2 pr-2">
                                        <Portfolio
                                            title={portfolio.title}
                                            thumb={portfolio.thumb}
                                            category={portfolio.category}
                                            link={portfolio.link}
                                            classes={'portfolio-screen-half'}
                                        />
                                    </div>
                                ))}

                            </Slider>

                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    );
};

export default forwardRef(PortfolioAuswahl);
