import React from 'react';
import PropTypes, { checkPropTypes } from "prop-types";

const PortfolioDetailsThumb = ({images}) => {
console.log("Portfolioe Rerender Images");
images.map((image, index) =>{
});
    return (
        <div className="portfolio-details-image mb-sm-30 mb-xs-30">
            {images.map((image, index) => (
                
                image.includes("mp4")?
                <video key={index+image}  alt="portfolio"  src={require('../../../../assets/images/portfolio/' + image).default} autoPlay loop muted playsInline type="video/mp4"/>
                :
                <img key={index} src={require('../../../../assets/images/portfolio/' + image).default} alt="portfolio"/>
            ))}
        </div>
    );
};




PortfolioDetailsThumb.prototype = {
    images: PropTypes.array
};

PortfolioDetailsThumb.defaultProps = {
    images: [
        "https://via.placeholder.com/670x430",
        "https://via.placeholder.com/670x430"
    ]
};

export default PortfolioDetailsThumb;