import React from 'react';
import {NavLink} from "react-router-dom";
//import navbarData from '../../data/navbar';
import navbarData from '../../data/navbarDyn';
import galerieData from '../../data/portfolio/galerien.json';

const NavItem = () => {

    const closeSideMenu = () => {
        const sideMenuWrap = document.querySelector(".side-header");
        const overlay = document.querySelector('.side-menu-overlay');
        sideMenuWrap.classList.remove('side-menu-open');
        overlay.classList.remove('overlay-show');
    };

    const buildMenu = (navItem, index) => {

        if (navItem.title == "Works") {

            navItem.subMenu=[];
            for(let i=0;i<galerieData.galerien.length;i++)
            {
                if(galerieData.galerien[i].menu==1)
                {
                    navItem.subMenu.push({
                        "title":galerieData.galerien[i].title,
                        "link":galerieData.galerien[i].link
                    });
                }
            }

            return(
            <li  key={index} className={navItem.subMenu ? 'menu-item-has-children' : null}>
                <NavLink
                        exact={navItem.link === `/`}
                        to={`${process.env.PUBLIC_URL + navItem.link}`}
                        activeClassName={'active'}>
                        {navItem.title}
                </NavLink>
                {navItem.subMenu ? (
                        <ul className="sub-menu">
                            {navItem.subMenu.map((subItem, indexb) => (
                                <li key={index + "." + indexb}
                                    className={subItem.subMenu ? "menu-item-has-children" : null}>
                                    <NavLink to={`${process.env.PUBLIC_URL + subItem.link}`}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            closeSideMenu();
                                        }}
                                    >{subItem.title}</NavLink>

                                    {subItem.subMenu ? (
                                        <ul className="sub-menu">
                                            {subItem.subMenu.map((dropSubItem, indexc) => (
                                                <li key={index + "." + indexb + "." + indexc}>
                                                    <NavLink to={`${process.env.PUBLIC_URL + dropSubItem.link}`}
                                                    >
                                                        {dropSubItem.title}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                </li>
                            ))}
                        </ul>
                    ) : null}
            </li>
            );
        }
        else {

            return (
                <li key={index} className={navItem.subMenu ? 'menu-item-has-children' : null}>
                    <NavLink
                        exact={navItem.link === `/`}
                        to={`${process.env.PUBLIC_URL + navItem.link}`}
                        activeClassName={'active'}>
                        {navItem.title}
                    </NavLink>

                    {navItem.subMenu ? (
                        <ul className="sub-menu">
                            {navItem.subMenu.map((subItem, indexb) => (
                                <li key={index + "." + indexb}
                                    className={subItem.subMenu ? "menu-item-has-children" : null}>
                                    <NavLink to={`${process.env.PUBLIC_URL + subItem.link}`}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            closeSideMenu();
                                        }}
                                    >{subItem.title}</NavLink>

                                    {subItem.subMenu ? (
                                        <ul className="sub-menu">
                                            {subItem.subMenu.map((dropSubItem, indexc) => (
                                                <li key={index + "." + indexb + "." + indexc}>
                                                    <NavLink to={`${process.env.PUBLIC_URL + dropSubItem.link}`}
                                                    >
                                                        {dropSubItem.title}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </li>
            );
        }
    };

    return (
        navbarData.map((navItem,index) => (
                      
                 buildMenu(navItem,index)
           

        ))
    );
};

export default NavItem;
