import React from 'react';
import GalerieBannerContainer from "../../../container/GalerieBanner";
import bannerBg from '../../../assets/images/bg/messageboard.jpg'
import personalInfo from '../../../data/PersonalInfo'

const BannerGalerie = ({bgimage}) => {
    return (
        <GalerieBannerContainer  bgimage={bgimage} overlay={false}>
            <div className="main-slider-content" >
               
                <h2>{personalInfo.designation}</h2>
                <h2>{personalInfo.designation2}</h2>
                <h2>{personalInfo.designation3}</h2>
            </div>

        </GalerieBannerContainer>
    );
};

export default BannerGalerie;