import React, {Fragment,useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


/*
* @ Component Imported
* */
import PortfolioGalerie from "./pages/home/PortfolioGalerie";
import HomeJH from "./pages/home/HomeJH";
import PortfolioDetailsPage from "./pages/portfolio/details";
import ImpressumPage from "./pages/Impressum";
import DatenschutzPage from "./pages/Datenschutz";
import ServicePage from "./pages/service";
import TeamPage from "./pages/team";
import ContactPage from "./pages/contact";
import ContactPageDanke from "./pages/contactDanke";
import portfolioGalerien from './data/portfolio/galerien';
import portfolioAllgemein from './data/portfolio/Portfolio';

import ErrorPage from "./pages/404Error";


const App = () => {
       
       const [access,setAccess]=useState(true);
       const [pass,setPass]=useState(null);

       var galerien=[];
       for(var i=0;i<portfolioGalerien.galerien.length;i++)
       {
              galerien.push(require('./data/portfolio/'+portfolioGalerien.galerien[i]["data"]+'.json'));
       }

       function handleChange(event){
              setPass(event.target.value);
       }
       
function handleSubmit(event){
       if(pass==="letmein") setAccess(true);
       event.preventDefault()
}
     
    return (
        <Fragment>

               {!access &&
              <form style={{zIndex:3000}} onSubmit={handleSubmit}>
  <label>
    Kennwort:
    <input type="text" name="name" value={pass} onChange={handleChange}/>
  </label>
  <input type="submit" value="Submit" />
</form>
}
{access &&
            <Router>
                <Switch>

                {galerien.map((portfolios,index) => (

                     portfolios.map((portfolio,index) => (
                                   <Route key={index} path={`${process.env.PUBLIC_URL +"/"+ portfolio.link}`}
                                   render={(props) => (
                                   <PortfolioDetailsPage {...props}  link={portfolio.link} />
                                   )}/>
                     
                    

                                   
                     ))


                ))};

              {portfolioGalerien.galerien.map((portfolio,index) => (
                                   <Route key={index} path={`${process.env.PUBLIC_URL + portfolio.link}`}
                                   render={(props) => (
                                   <PortfolioGalerie {...props} title={portfolio.title} text={portfolio.text} website={portfolio.website} data={portfolio.data} link={portfolio.link} image={portfolio.bannerimage} />
                                   )}/>
                     ))}



              {portfolioAllgemein.map((portfolio,index) => (
                                   <Route key={index} path={`${process.env.PUBLIC_URL +"/"+ portfolio.link}`}
                                   render={(props) => (
                                   <PortfolioDetailsPage {...props}  link={portfolio.link} />
                                   )}/>
                     ))}


,  
 
 {/** 
<Route path={`${process.env.PUBLIC_URL + "/janhellwig"}`}
                           component={ContactPage}/>
*/}

<Route path={`${process.env.PUBLIC_URL +"/janhellwig"}`}
                                   render={(props) => (
                                   <PortfolioDetailsPage {...props}  link="janhellwig" />
                                   )}/>

                    <Route path={`${process.env.PUBLIC_URL + "/Kontakt"}`}
                           component={ContactPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/NachrichtGesendet"}`}
                           component={ContactPageDanke}/>
                    <Route path={`${process.env.PUBLIC_URL + "/team"}`}
                           component={TeamPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service"}`}
                           component={ServicePage}/>
                  
                    <Route path={`${process.env.PUBLIC_URL + "/impressum"}`}
                           component={ImpressumPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/datenschutz"}`}
                           component={DatenschutzPage}/>
                  
                    <Route path={`${process.env.PUBLIC_URL + "/home"}`}
                           component={HomeJH}/>

                    <Route exact path={`${process.env.PUBLIC_URL + "/"}`}
                           component={HomeJH}
                    />
                    <Route exact component={ErrorPage}/>
                </Switch>
            </Router>
            }
        </Fragment>
    );
};

export default App;