
import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
// File imported
import Header from '../../components/header/HeaderOne'
import SideHeader from "../../components/SideHeader";
import FooterNav from "../../components/footer/FooterThree";
import BannerPersonalPortfolio from "../../components/HeroBanner/Galerie";


const PortfolioGalerien = (Props) => {
   let bgimage=Props.image;
    let portfolioData = require("../../data/portfolio/"+Props.data+".json");

    const isSticky = e => {
        const header = document.querySelector('.header-area');
        const scrollTop = window.scrollY;
        if(header) scrollTop >= 1 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);

        return () => {
            window.removeEventListener('scroll', isSticky);
        }
    }, []);

    const slider  = React.useRef(null);

    const SlideNext = ()=>{
        slider.current.slickNext();
    };

    const SlidePrev = ()=>{
        slider.current.slickPrev();
    };

    return (
        <div className={'main-wrapper p-0'}>

            <Header classes={'header-area section fixed-top'}/>
            <SideHeader mobile={true}/>

            <BannerPersonalPortfolio bgimage={bgimage}/>
            
            <div className="content-body bg-white section" style={{height:"100%"}}>
<Container>
            <Row>
                    <Col>
                        <div className="section-style">
                            <div className="section-title">
                                <br/>
                                <h2>{Props.title}</h2>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col  >
                    
                        <div className="rr_galleryText">
                            <p>
                            {Props.text.split('\n').map(c => {
                            return ( <p> {c} </p> ) 
                            })}
                           

                           </p>

                           {Props.website? <p><a href={Props.website}>{Props.website}</a></p>:null}
                        </div>
                    </Col>
                </Row>

                </Container>
                </div>
                <div className="galerie-projekte bg-grey section" style={{height:"100%"}} id="galerie-area"> {/** section-padding */}
            <Container>
               

                <Row>
                    {portfolioData.map((portfolio,index) => (
                        <Col key={index+portfolio.link} md={6} lg={4}>
                            <div className="galerie-item">
                                 <Link to={portfolio.link?`${process.env.PUBLIC_URL + portfolio.link}`:`${process.env.PUBLIC_URL + portfolio.galerieLink}`}
                              
                                      className="image"
                                      target="_self">
                                    <img src={require('../../assets/images/' + portfolio.thumb).default}
                                         alt={portfolio.title}/>
                                    <i className="fa fa-long-arrow-right"/>
                                </Link>
                                <h4 className="title">
                                    <a href={portfolio.link?`${process.env.PUBLIC_URL + portfolio.link}`:`${process.env.PUBLIC_URL + portfolio.galerieLink}`}
                                       target="_self"
                                       rel="noopener noreferrer">
                                        {portfolio.title}
                                    </a>
                                </h4>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
        <FooterNav position={'static'}/>
        </div>
    );
};

export default PortfolioGalerien;
