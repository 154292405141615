import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom';
const FooterThree = ({position}) => {
    return (
        <div className={`footer-section section position-${position} pt-30 pb-30`}>
            <Container fluid>
                <Row className={'align-items-center'}>
                <Col className={'footer-copyright text-left'}>
                        <p>&copy; {new Date().getFullYear()} Jan Hellwig</p>
                    </Col>
                    <Col className={'footer-copyright text-right'}>
                    <Link to={`${process.env.PUBLIC_URL + "/impressum"}`} >
                    Impressum
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to={`${process.env.PUBLIC_URL + "/datenschutz"}`} >
                    Datenschutz
                    </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterThree;
