import React from 'react';
import {Container,Carousel} from 'react-bootstrap';
import bannerBilder from '../../data/banner/index.json';



const HeroBannerContainer = ({children, overlay, bg}) => {
    //style={{backgroundImage: `url(${bg})`}}
    return (
        <div className={`section main-slider-1 ${overlay ? 'banner-overlay' : null}`} > 
 
<Carousel fade className="carousel" interval={5000}>
                    


                    {bannerBilder.map((bannerBild,index) => (

                    <Carousel.Item>
                    <img key={index} className="backImage" width={bannerBild.width} height={bannerBild.height} alt="background" src={require('../../assets/images/bg/'+bannerBild.image).default} />
                    </Carousel.Item>
                                   
                     ))}
                    
                   

</Carousel>

            <Container className="rr_slope rr_slopstart"style={{width:"100%", position:"absolute",zIndex:"100",left:"0px"}}>
                {children}
                
            </Container>
        </div>
    );
};

export default HeroBannerContainer;