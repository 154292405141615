import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
// File imported
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import BannerPersonalPortfolio from "../../components/HeroBanner/PersonalPortfolio";
import Contact from "../../container/contact";
import PortfolioSlider from "../../components/footer/FooterSlider";
import FooterNav from "../../components/footer/FooterThree";
import Projektgalerie from "../../templates/projektgalerie/ProjektGalerie";
import galerien from '../../data/portfolio/galerien.json';
import texteHome from '../../data/texte/texteHome.json';

import PortfolioAuswahl from "../../container/portfolio/PortfolioAuswahl";

const HomeRR = () => {

    const isSticky = e => {
        const header = document.querySelector('.header-area');
        const scrollTop = window.scrollY;
        if(header)scrollTop >= 70 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);

        return () => {
            window.removeEventListener('scroll', isSticky);
        }
    }, []);

    const slider  = React.useRef(null);

    const SlideNext = ()=>{
        slider.current.slickNext();
    };

    const SlidePrev = ()=>{
        slider.current.slickPrev();
    };

    return (
        <div className={'main-wrapper p-0'}>
            
            <Header classes={'header-area section fixed-top'}/>
            <SideHeader mobile={true}/>
          
            <BannerPersonalPortfolio/>
          
            <div className="bg-white section ">
            <div className="content-body bg-white section" style={{height:"100%"}}>
            <Container>
                <Row>
                    <Col  >
                        <div className="rr_introText">
                            <h2>{texteHome[0]["title"]} </h2>
                            <p>
                            {texteHome[0]["description"]}
                            </p>
                            <p>
                            {texteHome[2]["description"]}
                            </p>
                        </div>
                        
                    </Col>
                </Row>
             

          
            </Container>
            </div>
            </div>
            
            {/** 
            <div className="bg-grey section rr_slope rr_slopestart"></div>
            <div className="bg-grey section  ">
                
             <Projektgalerie galerien={galerien.galerien}/>
            <br/>
            <div className="bg-grey section rr_slope rr_slopeend"></div>
</div>


<div className="bg-white section rr_slope rr_slopeend"></div>
*/}
<div className="bg-white section">
            <Container >
                
            <Row>
                    <Col>
                    
                    </Col>
                </Row>
                </Container>
                
            <PortfolioAuswahl isMobile={true} ref={slider}/>
            <PortfolioSlider position={'static'} prev={SlidePrev} next={SlideNext}/>
            
            </div>
            {/** 
            <div className="bg-white section">
            <Contact/>
            </div>
            */}
            <FooterNav position={'static'}/>
            
        </div>
    );
};

export default HomeRR;
