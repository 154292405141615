import React from 'react';

const PortfolioDetailsContent = ({title, category, description, meta}) => {

    var dokumente=[];
    for(var i=0;i<meta.documents.length;i++)
    {
           dokumente.push(require('../../../../assets/documents/'+meta.documents[i]).default);
    }

    return (
        <div className="portfolio-details-content">

            <a href="/" className="category">{category}</a>

            <h1 className="title">{title}</h1>

            <p>
            {description.split('\n').map(c => {
                  return ( <p> {c} </p> ) 
                   })}
            </p>

            <ul className="project-info">
                <li><span>Client:</span> {meta.client}</li>
                <li><span>Date:</span> {meta.date}</li>
                <li><span>Website:</span>
                    <a href={meta.website}
                       target="_blank"
                       rel="noopener noreferrer">{meta.website}
                    </a>
                </li>
                <li>
                    <span>Service:</span>
                    {meta.services.map((service,index) => (
                        <div key={index} >{service}</div>
                    ))}
                </li>
                {meta.documents?
                    <li>
                        <span>Dokumente:</span>
                        {dokumente.map((document,index) => (
                            <a key={index} href={document} target="_blank">{meta.documents[index]}</a>
                           
                        ))}
                    </li>
                    :null
                }

                {/**
                <li>
                    <span>Kooperation:</span>
                    {meta.kooperation.map((kooperation,index) => (
                        <a key={index}>{kooperation }</a>
                    ))}
                </li>
                 */}
            </ul>

{/** 
            <div className="project-share">
                <span>Share:</span>
                <a href="/"><i className="fa fa-facebook"/></a>
                <a href="/"><i className="fa fa-twitter"/></a>
                <a href="/"><i className="fa fa-pinterest-p"/></a>
                <a href="/"><i className="fa fa-google-plus"/></a>
            </div>
            */}

        </div>
    );
};

export default PortfolioDetailsContent;