import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PortfolioDetailsThumb from "./thumbnail";
import PortfolioDetailsContent from "./content";
import portfolioGalerien from '../../../data/portfolio/galerien';

const TemplatePortfolioDetails = (Props) => {
    
    var galerien=[];
    var index=0;
    for(var i=0;i<portfolioGalerien.galerien.length;i++)
    {
           galerien.push(require('../../../data/portfolio/'+portfolioGalerien.galerien[i]["details"]+'.json'));
    }

    let portfolioData;

    for(var i=0;i<galerien.length;i++)
    {
        if(galerien[i].find(element => element.link ==Props.link))
        {
            
            
            portfolioData=galerien[i].find(element => element.link ==Props.link); 
            index=i;
        }
    }

    if(!portfolioData)console.log("WARNING: Portfolio Details nicht gefunden!");
  
   

  //findIndex() onClick={prev} onClick={next}
    {/**  <PageContainer classes={'bg-grey'}>*/}
    return (
        <div class="bg-projekte section">
            <Container>
                {/** 
                <Row>
                    <Col lg={12}>
                    <div className="row align-items-center">
                    <div className="portfolio-slider-5-nav">
                            <button
                                type="button"
                                className="slick-prev slick-arrow"
                                onClick={(galerien,index)=>{portfolioData=galerien[index-1].find(element => element.link ==Props.link); }}> prev
                            </button>
                            <button
                                type="button"
                                className="slick-next slick-arrow"
                                onClick={(galerien,index)=>{portfolioData=galerien[index+1].find(element => element.link ==Props.link); }}>next
                            </button>
                            <br/>
                        </div>
                        </div>
                    </Col>
                </Row>
                */}
                <Row>
                    <Col lg={7}>
                        <PortfolioDetailsThumb  images={portfolioData.previewImages}/>
                    </Col>

                    <Col lg={5}>
                        
                        <PortfolioDetailsContent
                            title={portfolioData.title}
                            category={portfolioData.category}
                            description={portfolioData.description}
                            meta={portfolioData.meta}
                        />
                    </Col>
                </Row>
                
             </Container>
            </div> 
       
    );
};
{/** </PageContainer>*/}
export default TemplatePortfolioDetails;