import React from 'react';
import {Container,Carousel} from 'react-bootstrap';


const HeroBannerContainer = ({children, overlay, bgimage}) => {
    //style={{backgroundImage: `url(${bg})`}}
    console.log("Image: "+bgimage);
    console.log("Overlay: "+overlay);
    
    return (
        <div className={`section main-slider-1 ${overlay ? 'banner-overlay' : null}`} style={{height:"300px"}} > 
 
<Carousel fade className="carousel" interval={0}>

                    <Carousel.Item>
                    <img className="backImage" width={1920} height={800} alt="background" src={require('../../assets/images/bg/' + bgimage).default} />
                    </Carousel.Item>
                  
                    

</Carousel>

            <Container className="rr_slope rr_slopstart"style={{width:"100%", position:"absolute",zIndex:"100",left:"0px"}}>
                {children}
                
            </Container>
        </div>
    );
};

export default HeroBannerContainer;