import React, {Fragment,useEffect} from 'react';
import Header from "../../../components/header/HeaderOne";
import SideHeader from "../../../components/SideHeader";
import FooterThree from "../../../components/footer/FooterThree";
import TemplatePortfolioDetails from "../../../templates/portfolio/details";

const PortfolioDetailsPage = (Props) => {

  
    
    return (
        <Fragment>
             <Header classes={'project-header fixed-top'}/>
            <SideHeader mobile={true}/>
            <TemplatePortfolioDetails link={Props.link}/>
            
            <FooterThree position='static'/>
        </Fragment>
    );
};

export default PortfolioDetailsPage;